<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row>
      <v-col cols="12" lg="8">
        <FieldContainer
          v-if="serverData?.organizations"
          :label="$t('organization')"
        >
          <v-autocomplete
            v-model="formData.organization_id"
            class="rounded-lg h-45px"
            :items="serverData.organizations"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :error-messages="organization_idErrors"
            @blur="$v.formData.organization_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer
          v-if="serverData?.clients || !$store.getters.isClient"
          :label="$t('customer')"
        >
          <v-autocomplete
            v-model="formData.customerId"
            class="rounded-lg h-45px"
            :items="filteredClients"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :error-messages="customerIdErrors"
            @blur="$v.formData.customerId.$touch()"
            @change="(event) => handleChangeCustomer(event)"
          />
        </FieldContainer>

        <FieldContainer label="SKU Type">
          <v-autocomplete
            v-model="formData.sku_type_id"
            class="rounded-lg h-45px"
            :items="serverData.sku_types"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
          />
        </FieldContainer>

        <FieldContainer :label="$t('system_code')">
          <v-text-field
            v-model="formData.systemCode"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            :error-messages="systemCodeErrors"
            @blur="$v.formData.systemCode.$touch()"
          />
        </FieldContainer>

        <FieldContainer :label="$t('description')">
          <v-text-field
            v-model="formData.description"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            :error-messages="descriptionErrors"
            @blur="$v.formData.description.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Base UOM">
          <v-autocomplete
            v-model="formData.base_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :error-messages="base_uom_idErrors"
            @blur="$v.formData.base_uom_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Alter SKU Name">
          <v-text-field
            v-model="formData.alterSkuName"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
          />
        </FieldContainer>

        <FieldContainer :label="$t('photo')">
          <v-file-input
            v-model="formData.photo"
            class="rounded-lg h-45px"
            show-size
            multiple
            clearable
            outlined
            counter
            dense
            accept="image/png, image/jpeg, image/bmp"
            append-icon="mdi-camera"
            prepend-icon=""
          />
        </FieldContainer>

        <FieldContainer :label="$t('weight')">
          <v-text-field
            v-model.number="formData.weight"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('weight')"
          />
        </FieldContainer>

        <FieldContainer label="Gross weight">
          <v-text-field
            v-model.number="formData.gross_weight"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('gross_weight')"
          />
        </FieldContainer>

        <FieldContainer label="Weight UOM">
          <v-autocomplete
            v-model="formData.weight_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
          />
        </FieldContainer>

        <FieldContainer :label="$t('length')">
          <v-text-field
            v-model.number="dimension.length"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('length', 0, 'dimension')"
          />
        </FieldContainer>

        <FieldContainer :label="$t('width')">
          <v-text-field
            v-model.number="dimension.width"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('width', 0, 'dimension')"
          />
        </FieldContainer>

        <FieldContainer :label="$t('height')">
          <v-text-field
            v-model.number="dimension.height"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('height', 0, 'dimension')"
          />
        </FieldContainer>

        <FieldContainer :label="$t('volume')">
          <v-text-field
            class="rounded-lg h-45px"
            v-model.number="dimension.cube"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('cube', 0, 'dimension')"
          />
        </FieldContainer>

        <FieldContainer label="Volume UOM">
          <v-autocomplete
            v-model="formData.volume_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :error-messages="volume_uom_idErrors"
            @blur="$v.formData.volume_uom_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="CBM">
          <!--            v-model.number="formData.cbm"-->
          <v-text-field
            class="rounded-lg h-45px"
            readonly
            :value="cbm"
            clearable
            outlined
            dense
            type="number"
            :min="0"
          />
          <!--            @blur="() => validateMinValue('cbm')"-->
        </FieldContainer>

        <FieldContainer :label="$t('price')">
          <v-text-field
            v-model.number="formData.price"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('price')"
          />
        </FieldContainer>

        <FieldContainer :label="$t('currency')">
          <v-autocomplete
            v-model="formData.currency_id"
            class="rounded-lg h-45px"
            :items="serverData.currencies"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
          />
        </FieldContainer>

        <FieldContainer :label="$t('cost_price')" :divider="false">
          <v-text-field
            v-model.number="formData.cost_price"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            type="number"
            :min="0"
            @blur="() => validateMinValue('cost_price')"
          />
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_SELECTED_CUSTOMER } from "@/core/services/store/auth.module";

import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/fulfillment/skus/forms/FieldContainer.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";

export default {
  name: "MainFormFields",
  mixins: [validationMixin, fieldValueValidation],
  components: { FieldContainer },
  props: ["serverData", "pageLoader", "actionItem", "refresher"],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function() {
          return !!this.serverData.organizations;
        }),
      },
      customerId: {
        required: requiredIf(function() {
          return !!this.serverData.clients || !this.$store.getters.isClient;
        }),
      },
      systemCode: { required },
      description: { required },
      base_uom_id: { required },
      // weight: { required },
      // gross_weight: { required },
      // weight_uom_id: { required },
      volume_uom_id: { required },
    },
    // dimension: {
    //   length: { required },
    //   width: { required },
    //   height: { required },
    // },
  },
  data: () => ({
    formData: {
      organization_id: null,
      customerId: null,
      sku_type_id: null,
      systemCode: null,
      description: null,
      base_uom_id: 1,
      alterSkuName: null,
      weight: null,
      gross_weight: null,
      weight_uom_id: 5,
      volume_uom_id: 8,
      cbm: null,
      price: null,
      currency_id: null,
      cost_price: null,
      photo: null,
    },
    dimension: {
      length: null,
      width: null,
      height: null,
      cube: null,
    },
    serverFilteredClients: [],
  }),
  methods: {
    handleChangeCustomer(event) {
      this.$store.commit(SET_SELECTED_CUSTOMER, event);
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);

      let url = `/fulfillment/skus/${this.actionItem ? `update` : `store`}`;
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });

      // data.append("cube", this.cube);
      data.append("cube", this.dimension.cube);
      data.append("cbm", this.cbm);
      data.append("length", this.dimension.length);
      data.append("height", this.dimension.height);
      data.append("width", this.dimension.width);
      if (this.actionItem) {
        data.append("id", this.actionItem.id);
        data.append("tab_name", "main");
      }

      ApiService.post(url, data)
        .then(({ data }) => {
          if (data.sku) {
            this.$store.commit(SET_ITEM_FOR_ACTION, data.sku);
          }
          const title = this.actionItem
            ? this.$t("updated")
            : this.$t("created");
          SwalService.successMessage({
            title: title,
            html: this.$t("submitted_successfully"),
          });
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    getFormData() {
      // console.log(this.serverData);
      const {
        organization_id,
        customerId,
        sku_type_id,
        systemCode,
        description,
        base_uom_id,
        alterSkuName,
        weight,
        gross_weight,
        weight_uom_id,
        length,
        width,
        height,
        volume_uom_id,
        cube,
        cbm,
        price,
        currency_id,
        cost_price,
      } = this.serverData.sku;

      this.dimension = {
        length,
        width,
        height,
        cube,
      };

      this.formData = {
        ...this.formData,
        organization_id,
        customerId,
        sku_type_id,
        systemCode,
        description,
        base_uom_id,
        alterSkuName,
        weight,
        gross_weight,
        weight_uom_id,
        volume_uom_id,
        // cube,
        cbm,
        price,
        currency_id,
        cost_price,
      };
      this.serverFilteredClients = this.serverData.clients;
      this.handleChangeCustomer(customerId);
    },
    getClients() {
      this.serverFilteredClients = this.serverData.clients;
      this.reset();
      this.formData.base_uom_id = 1;
      this.formData.weight_uom_id = 5;
      this.formData.volume_uom_id = 8;
    },
    reset() {
      this.$v.$reset();
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = null;
      });

      Object.keys(this.dimension).forEach((key) => {
        this.dimension[key] = null;
      });

      this.formData.base_uom_id = 1;
      this.formData.weight_uom_id = 5;
      this.formData.volume_uom_id = 8;
    },
  },
  computed: {
    selectedOrganization() {
      return this.formData.organization_id;
    },
    filteredClients() {
      return this.serverFilteredClients;
    },
    organization_idErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    customerIdErrors() {
      return this.handleFormValidation("customerId", this);
    },
    descriptionErrors() {
      return this.handleFormValidation("description", this);
    },
    systemCodeErrors() {
      return this.handleFormValidation("systemCode", this);
    },
    base_uom_idErrors() {
      return this.handleFormValidation("base_uom_id", this);
    },
    // weightErrors() {
    //   return this.handleFormValidation("weight", this);
    // },
    // gross_weightErrors() {
    //   return this.handleFormValidation("gross_weight", this);
    // },
    // weight_uom_idErrors() {
    //   return this.handleFormValidation("weight_uom_id", this);
    // },
    volume_uom_idErrors() {
      return this.handleFormValidation("volume_uom_id", this);
    },
    // lengthErrors() {
    //   return this.handleFormValidation("length", this, "dimension");
    // },
    // heightErrors() {
    //   return this.handleFormValidation("height", this, "dimension");
    // },
    // widthErrors() {
    //   return this.handleFormValidation("width", this, "dimension");
    // },
    // cube: function() {
    //   const { width, height, length } = this.dimension;
    //   if (width && height && length) {
    //     const res = width * height * length;
    //     return Math.round(res * 100) / 100;
    //     // return Number(width * height * length).toFixed(2);
    //   } else return null;
    // },
    cbm: function() {
      const { width, height, length } = this.dimension;
      if (width && height && length) {
        const res = (width * height * length) / 1000000;
        return Math.round(res * 100) / 100;
        // return Number((width * height * length) / 1000000).toFixed(2);}
      } else return null;
    },
  },
  watch: {
    selectedOrganization(newValue) {
      // console.log("newValue", newValue);
      if (newValue) {
        this.serverFilteredClients = this.serverData.clients.filter(
          (client) => client.organization_id === newValue
        );
      }
    },
  },
};
</script>
